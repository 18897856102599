import React, { useState, useCallback, useRef, useEffect } from 'react';
import { ClickAwayListener, IconButton, Popper, PopperProps } from '@mui/material';
import Icon from 'components/ui-components-v2/Icon';
import './styles/main.scss';

interface Props {
    title?: string;
    clickAway?: boolean;
    open?: boolean;
    button: React.ReactElement;
    placement?: PopperProps['placement'];
    disablePortal?: PopperProps['disablePortal'];
    width?: number;
    height?: number;
    children: React.ReactNode;
}

/**
 * Advanced Box Settings component
 */
const PopupPanel = ({ title, clickAway = false, open, button, placement = 'left-end', disablePortal = false, width, height, children }: Props) => {
    const [defaultOpen, setDefaultOpen] = useState(open || false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const infiniteViewerScrollbars = useRef(document.querySelectorAll('.infinite-viewer-scroll-thumb'));
    const rightSidebar = useRef(document.querySelector('.template-designer__design__sidebar-right'));

    useEffect(() => {
        if (open === undefined) return;
        setDefaultOpen(open);
    }, [open]);

    /**
     * Handle click event.
     * @param event - React mouse event.
     */
    const handleClick = (event?: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event ? event.currentTarget : null);
        setDefaultOpen((previousOpen) => !previousOpen);
        changeAdvancedTextOptionsStyle(!open);
    };

    /**
     * Change the style of the advanced text options.
     */
    const changeAdvancedTextOptionsStyle = useCallback(
        (show: boolean) => {
            if (show) {
                infiniteViewerScrollbars.current?.forEach((scrollbar) => {
                    (scrollbar as HTMLElement).style.zIndex = '2';
                });

                (rightSidebar.current as HTMLElement)?.style.setProperty('overflow-y', 'visible');
                document.body.style.setProperty('overflow', 'hidden');
            } else {
                infiniteViewerScrollbars.current?.forEach((scrollbar) => {
                    (scrollbar as HTMLElement).style.zIndex = '10';
                });

                (rightSidebar.current as HTMLElement)?.style.setProperty('overflow-y', 'auto');
                document.body.style.setProperty('overflow', '');
            }
        },
        [open, rightSidebar, infiniteViewerScrollbars]
    );

    const canBeOpen = defaultOpen && Boolean(anchorEl);
    const id = canBeOpen ? 'spring-popper' : undefined;

    const style: React.CSSProperties = { width: width ? width : 270 };
    if (height) style.height = height;

    const popupContent = (
        <Popper
            id={id}
            disablePortal={disablePortal}
            open={defaultOpen}
            anchorEl={anchorEl}
            placement={placement}
            className="template-designer__popup-panel"
            style={style}>
            <div className="template-designer__popup-panel__background">
                {title && (
                    <div className="template-designer__popup-panel__title-container">
                        {title && <div className="template-designer__popup-panel__title-container__separator">{title}</div>}
                        <IconButton onClick={handleClick} className="template-designer__popup-panel__title-container__button">
                            <Icon className="template-designer__popup-panel__title-container__button__icon">close</Icon>
                        </IconButton>
                    </div>
                )}
                <div className="template-designer__popup-panel__container">{children}</div>
            </div>
        </Popper>
    );

    const openButton = (() => {
        if (open === undefined) {
            return React.cloneElement(button, {
                id,
                onClick: handleClick
            });
        }

        return React.cloneElement(button, {
            id,
            onClick: (event) => {
                handleClick(event);
                button.props.onClick(event);
            }
        });
    })();

    return (
        <>
            {openButton}
            {defaultOpen && (
                <>
                    {clickAway && <ClickAwayListener onClickAway={() => handleClick()}>{popupContent}</ClickAwayListener>}
                    {!clickAway && popupContent}
                </>
            )}
        </>
    );
};

export { PopupPanel };
