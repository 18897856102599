import React, { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import { IconProps } from '@mui/material';
import classNames from 'classnames';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { EventEmitterTypes } from 'types/event-emitter.type';
import { useEventEmitterListener } from 'hooks/useEventEmitterListener';
import Icon from 'components/ui-components-v2/Icon';
import Tooltip from 'components/ui-components-v2/Tooltip';
import { OverwriteIndicator } from './components/overwrite-indicator';
import './main.scss';

export type ExpandSections =
    | 'transform'
    | 'transition'
    | 'style'
    | 'text'
    | 'background'
    | 'spacing'
    | 'appearance'
    | 'border'
    | 'boxShadow'
    | 'formatStyling'
    | 'formatScript'
    | 'layerSettings'
    | 'boxSettings'
    | 'smartLayout'
    | 'audio'
    | 'text'
    | 'lottie'
    | 'spacing'
    | 'media'
    | 'guides'
    | 'audio'
    | 'customCSS'
    | 'lottie'
    | 'scale'
    | 'dynamicOptions'
    | 'customCode'
    | 'inputSources'
    | 'mediaManipulation'
    | 'outputSettings'
    | 'otherSettings'
    | 'mainSettings';

interface Props {
    className?: string;
    spacing?: 'small' | 'medium' | 'large';
    expandSectionKey: ExpandSections;
    title?: string;
    tooltip?: string;
    tooltipIconColor?: IconProps['color'];
    tooltipIcon?: string;
    children?: React.ReactNode;
    totalOverwrites?: number;
    condition?: boolean;
    defaultOpen?: boolean;
    open?: boolean;
    setOpen?: (open: boolean) => void;
    disabled?: boolean;
    includeBorderBottom?: boolean;
    dataCyPrefix?: string;
}

const ExpandSection = ({
    className,
    spacing = 'small',
    expandSectionKey,
    title,
    tooltip,
    tooltipIconColor = 'warning',
    tooltipIcon = 'warning',
    children,
    totalOverwrites,
    condition,
    defaultOpen,
    open,
    setOpen,
    disabled,
    includeBorderBottom = true,
    dataCyPrefix
}: Props) => {
    const expandSectionEvent = useEventEmitterListener(EventEmitterTypes.TDlayerEditExpandSection);

    const [expanded, setExpanded] = useState<boolean>(defaultOpen || false);

    /**
     * Expand or collapse the section based on the accordion event.
     */
    useEffect(() => {
        if (!expandSectionEvent) return;

        if (expandSectionEvent.expandSectionKey === expandSectionKey || expandSectionEvent.expandSectionKey === 'all') {
            if (expandSectionEvent.action === 'open') setExpanded(true);
            if (expandSectionEvent.action === 'close') setExpanded(false);
        }
        if (expandSectionEvent.action === 'oneOpen') {
            if (expandSectionEvent.expandSectionKey === expandSectionKey) setExpanded(true);
            else setExpanded(false);
        }
    }, [expandSectionEvent]);

    /**
     * Set the default open state when it's changed.
     */
    useEffect(() => {
        if (defaultOpen === undefined) return;
        setExpanded(defaultOpen);
    }, [defaultOpen]);

    if (condition !== undefined && !condition) return null;

    return (
        <Accordion
            className={classNames(
                'template-designer__expand-section',
                {
                    ['template-designer__expand-section--border-bottom']: includeBorderBottom
                },
                className
            )}
            classes={{
                expanded: 'template-designer__expand-section--expanded',
                disabled: 'template-designer__expand-section--disabled'
            }}
            expanded={open ?? expanded}
            disabled={disabled}
            onChange={() => {
                if (open !== undefined && setOpen !== undefined) return setOpen(!open);
                setExpanded((prevState) => !prevState);
            }}
            TransitionProps={{ unmountOnExit: true }}>
            {(!disabled || title) && (
                <AccordionSummary
                    classes={{
                        root: classNames('template-designer__expand-section__summary', {
                            ['template-designer__expand-section__summary--small']: spacing === 'small',
                            ['template-designer__expand-section__summary--medium']: spacing === 'medium',
                            ['template-designer__expand-section__summary--large']: spacing === 'large'
                        }),
                        content: 'template-designer__expand-section__summary__title',
                        disabled: 'template-designer__expand-section__summary--disabled'
                    }}
                    expandIcon={!disabled ? <Icon className="template-designer__expand-section__expand">expand_more</Icon> : undefined}
                    data-cy={`${dataCyPrefix}Section-div`}>
                    {title && <h4 className="template-designer__expand-section__title">{title}</h4>}
                    {tooltip && (
                        <Tooltip title={tooltip}>
                            <Icon className="template-designer__expand-section__tooltip" color={tooltipIconColor}>
                                {tooltipIcon}
                            </Icon>
                        </Tooltip>
                    )}
                    {!!totalOverwrites && <OverwriteIndicator totalOverwrites={totalOverwrites} />}
                </AccordionSummary>
            )}
            <AccordionDetails
                classes={{
                    root: classNames('template-designer__expand-section__details', {
                        ['template-designer__expand-section__details--small']: spacing === 'small',
                        ['template-designer__expand-section__details--medium']: spacing === 'medium',
                        ['template-designer__expand-section__details--large']: spacing === 'large'
                    })
                }}>
                {children}
            </AccordionDetails>
        </Accordion>
    );
};

export { ExpandSection };
