import React from 'react';
import classNames from 'classnames';
import './styles/main.scss';

interface ToggleProps {
    className?: string;
    checked: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    id: string;
}

const Toggle = ({ className, checked, onChange, disabled, id }: ToggleProps) => (
    <label className={classNames('template-designer__toggle', className, disabled && 'template-designer__toggle--disabled')}>
        <input type="checkbox" checked={checked} onChange={onChange} readOnly id={id} />
        <div className="template-designer__toggle__container">
            <div className="template-designer__toggle__container__flip" />
        </div>
    </label>
);

export default Toggle;
