import React, { useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Icon from 'components/ui-components-v2/Icon';
import Tooltip from 'components/ui-components-v2/Tooltip';
import './styles/main.scss';
import { TEMPLATE_DESIGNER_TOOLTIP_DELAY } from 'components/template-designer/constants';

export interface DropdownProps {
    className?: string;
    trigger?: React.ReactElement;
    active?: boolean;
    open?: boolean;
    dropdownPosition?: { top: number | null; left: number | null };
    tooltips?: {
        [key: DropdownItem['key']]: string;
    };
    dataCyPrefix?: string;
    onClose?: () => void;
    onOpen?: () => void;
    items: DropdownItem[];
    keepOpen?: boolean;
}

export interface DropdownItem {
    key: string;
    children: React.ReactElement | string;
    className?: string;
    label?: string;
    active?: boolean;
    icon?: string;
    tooltip?: string;
    hide?: boolean;
    disabled?: boolean;
    onClick?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
    borderBottom?: boolean;
}

const Dropdown = ({ className, trigger, active = true, open, dropdownPosition, tooltips, dataCyPrefix, onClose, onOpen, items }: DropdownProps) => {
    const [dropdownElement, setDropdownElement] = useState<HTMLElement | null>(null);
    const [hoveredValue, setHoveredValue] = useState<DropdownItem['key'] | null>(null);
    const widestDivRef = useRef<{ current: HTMLElement | null; width: number }>({ current: null, width: 0 });
    const dropdownOpen = open ?? Boolean(dropdownElement);
    const anchorReference = dropdownPosition?.top && dropdownPosition.left ? 'anchorPosition' : undefined;

    /**
     * Store element in state so the dropdown can be positioned.
     * @param event - Event from trigger click.
     */
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setDropdownElement(event.currentTarget);
    };

    /**
     * Clear the element.
     */
    const handleClose = () => {
        setDropdownElement(null);
        onClose?.();
    };

    const anchorPos =
        dropdownPosition && dropdownPosition.top !== null && dropdownPosition.left !== null
            ? { top: dropdownPosition.top ?? 0, left: dropdownPosition.left ?? 0 }
            : undefined;

    /**
     * Get the label of the hovered item.
     */
    const hoveredLabel = useMemo(() => {
        if (!hoveredValue) return false;

        const label = items?.find((item) => item.key === hoveredValue)?.label;
        if (label) return label;

        const children = items?.find((item) => item.key === hoveredValue)?.children;
        if (typeof children === 'string') return children;

        return false;
    }, [hoveredValue, items]);

    return (
        <div className={classNames('template-designer__dropdown', className)}>
            {trigger &&
                React.cloneElement(trigger, {
                    onClick: (event) => {
                        if (!active) return;
                        event.stopPropagation();
                        event.preventDefault();
                        onOpen?.();
                        handleClick(event);
                    }
                })}
            {(dropdownElement || anchorPos) && (
                <Menu
                    classes={{
                        list: 'template-designer__dropdown__menu'
                    }}
                    anchorEl={dropdownElement}
                    open={dropdownOpen}
                    onClose={handleClose}
                    anchorReference={anchorReference}
                    anchorPosition={anchorPos}>
                    {items
                        .filter((item) => !item.hide)
                        .map((item) => (
                            <Tooltip key={item.key} title={item.tooltip ?? ''} placement="right" enterDelay={TEMPLATE_DESIGNER_TOOLTIP_DELAY}>
                                <div>
                                    <MenuItem
                                        className={classNames(
                                            'template-designer__dropdown__item',
                                            {
                                                'template-designer__dropdown__item--border-bottom': item.borderBottom,
                                                ['template-designer__dropdown__item--active']: item.active
                                            },
                                            item.className
                                        )}
                                        data-cy={`${dataCyPrefix}MoreMenu${item.key}-button`}
                                        disabled={item.disabled}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            item.onClick?.(event);
                                            handleClose();
                                        }}
                                        onMouseEnter={() => {
                                            setHoveredValue(item.key as DropdownItem['key']);
                                        }}
                                        ref={(el) => {
                                            if (el && el.offsetWidth > (widestDivRef.current?.current?.offsetWidth || 0)) {
                                                widestDivRef.current = { current: el, width: el.offsetWidth };
                                            }
                                        }}>
                                        {item.icon && (
                                            <Icon baseClassName="material-icons material-icons-outlined" className="template-designer__dropdown__item__icon">
                                                {item.icon}
                                            </Icon>
                                        )}
                                        <span className="template-designer__dropdown__item__label">{item.children}</span>
                                    </MenuItem>
                                </div>
                            </Tooltip>
                        ))}
                    {tooltips && hoveredValue && tooltips[hoveredValue] && widestDivRef.current && (
                        <div className="template-designer__dropdown__helper-text" style={{ width: widestDivRef.current.width }}>
                            {hoveredLabel && (
                                <span className="template-designer__dropdown__helper-text__label">
                                    {hoveredLabel} <br />
                                </span>
                            )}
                            {tooltips[hoveredValue]}
                        </div>
                    )}
                </Menu>
            )}
        </div>
    );
};

export default Dropdown;
