import React from 'react';
import classNames from 'classnames';

interface Props {
    className?: string;
    style?: React.CSSProperties;
    children?: JSX.Element | JSX.Element[];
}

const DialogContent = ({ className, style, children }: Props) => (
    <div className={classNames('template-designer__dialog__content', className)} style={style}>
        {children}
    </div>
);

export default DialogContent;
