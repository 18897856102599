import React from 'react';
import classNames from 'classnames';

interface Props {
    className?: string;
    style?: React.CSSProperties;
    children?: JSX.Element | JSX.Element[];
    alignRight?: boolean;
}

const DialogActions = ({ className, style, children, alignRight }: Props) => (
    <div
        className={classNames(
            'template-designer__dialog__actions',
            {
                'template-designer__dialog__actions--align-right': alignRight
            },
            className
        )}
        style={style}>
        {children}
    </div>
);

export default DialogActions;
