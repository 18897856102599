import React, { useEffect } from 'react';
import classNames from 'classnames';
import { DialogProps } from '@mui/material';
import '../styles/dialog.scss';

interface Props extends DialogProps {
    className?: string;
    style?: React.CSSProperties;
    children: React.ReactNode;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    clickAway: () => void;
}

const Dialog = ({ className, style, children, size = 'md', clickAway }: Props) => {
    //Escpape to close the dialog
    useEffect(() => {
        const escape = (e) => {
            if (e.key === 'Escape') clickAway();
        };
        document.addEventListener('keydown', escape);

        return () => {
            document.removeEventListener('keydown', escape);
        };
    }, []);

    let maxWidth;
    switch (size) {
        case 'xs':
            maxWidth = 444;
            break;
        case 'sm':
            maxWidth = 600;
            break;
        case 'lg':
            maxWidth = 1280;
            break;
        case 'xl':
            maxWidth = 1536;
            break;
        case 'md':
        default:
            maxWidth = 960;
            break;
    }

    const onClickOutside = (e) => {
        if (e.target.className === 'template-designer__dialog--cover' && clickAway) clickAway();
    };

    return (
        <>
            <div className="template-designer__dialog--cover" onClick={onClickOutside} />
            <div className={classNames('template-designer__dialog', className)} style={{ maxWidth, ...style }}>
                {children}
            </div>
        </>
    );
};

export default Dialog;
