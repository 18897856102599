import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import classNames from 'classnames';
import Chip from 'components/ui-components-v2/Chip';
import { TextInputField } from '../text-input-field';
import './styles/main.scss';

interface Props {
    className?: string;
    value: { value: string; label: string; image: string; disabled?: boolean } | { value: string; label: string; image?: string; disabled?: boolean }[];
    options: { key?: string; value: string; label: string; image?: string; disabled?: boolean }[];
    onChange: (value: Props['value']) => void;
    fullWidth?: boolean;
    size?: 'small' | 'medium';
    label?: string | JSX.Element;
    placeholder?: string;
    disabled?: boolean;
    InputProps?: any;
    multiple?: boolean;
    dataCyPrefix?: string;
}

export function AutocompleteImage({
    className,
    options,
    value,
    onChange,
    fullWidth,
    size = 'small',
    label,
    placeholder,
    disabled,
    multiple,
    dataCyPrefix
}: Props) {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    /**
     * A custom React hook to reset the cache of VariableSizeList when data changes.
     * @param {any} data - The data for which the cache needs to be reset.
     * @returns {RefObject} ref - A reference to the VariableSizeList component.
     */
    function useResetCache(data: any) {
        const ref = React.useRef<VariableSizeList>(null);
        React.useEffect(() => {
            if (ref.current !== null) {
                ref.current.resetAfterIndex(0, true);
            }
        }, [data]);
        return ref;
    }

    /**
     * A custom list component for Autocomplete using react-window.
     * @param {Object} props - The props object.
     * @param {Function} ref - Forwarded ref to the HTMLDivElement.
     * @returns {JSX.Element} - The JSX for the custom list component.
     */
    const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>((props, ref) => {
        const LISTBOX_PADDING = 8; // px

        const OuterElementContext = React.createContext({});

        const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
            const outerProps = React.useContext(OuterElementContext);
            return <div ref={ref} {...props} {...outerProps} />;
        });
        OuterElementType.displayName = 'OuterElementType';

        /**
         * Renders a single row of the listbox.
         */
        function renderRow(props: ListChildComponentProps) {
            const { data, index, style } = props;
            const dataSet = data[index][0];
            const inlineStyle = {
                ...style,
                top: (style.top as number) + LISTBOX_PADDING
            };
            const isSelected = (() => {
                if (!Array.isArray(value)) {
                    dataSet.value === value.value;
                } else {
                    return value.some((val) => val.value === dataSet.value);
                }
            })();

            return (
                <li
                    {...dataSet}
                    style={inlineStyle}
                    className="template-designer__autocomplete-image__option"
                    onClick={() => {
                        if (multiple && Array.isArray(value)) {
                            onChange([...value, dataSet]);
                        } else {
                            onChange(dataSet);
                        }
                        setIsOpen(false);
                    }}>
                    <div
                        className={classNames('template-designer__autocomplete-image__option__background', {
                            'template-designer__autocomplete-image__option__background--selected': isSelected
                        })}>
                        <img
                            src={dataSet.image}
                            alt={dataSet.label}
                            data-cy={`templateDesigner-autoComplete-${dataSet.key}-img`}
                            onClick={() => {
                                if (multiple && Array.isArray(value)) {
                                    onChange([...value, dataSet]);
                                } else {
                                    onChange(dataSet);
                                }
                                setIsOpen(false);
                            }}
                            className={classNames('template-designer__autocomplete-image__option__image', {
                                'template-designer__autocomplete-image__option__image--selected': isSelected
                            })}
                        />
                    </div>
                </li>
            );
        }
        const { children, ...other } = props;
        const itemData: React.ReactElement[] = [];
        (children as React.ReactElement[]).forEach((item: React.ReactElement & { children?: React.ReactElement[] }) => {
            itemData.push(item);
            itemData.push(...(item.children || []));
        });

        const itemCount = itemData.length;
        const itemSize = 28;

        const getHeight = () => {
            if (itemCount > 8) {
                return 8 * itemSize;
            }
            return itemData.map(() => itemSize).reduce((a, b) => a + b, 0);
        };

        const gridRef = useResetCache(itemCount);

        return (
            <div ref={ref}>
                <OuterElementContext.Provider value={other}>
                    <VariableSizeList
                        itemData={itemData}
                        height={getHeight() + 2 * LISTBOX_PADDING}
                        width="100%"
                        ref={gridRef}
                        outerElementType={OuterElementType}
                        innerElementType="ul"
                        itemSize={() => itemSize}
                        overscanCount={5}
                        itemCount={itemCount}
                        onClick={() => setIsOpen(false)}>
                        {renderRow}
                    </VariableSizeList>
                </OuterElementContext.Provider>
            </div>
        );
    });
    ListboxComponent.displayName = 'ListboxComponent';

    return (
        <Autocomplete
            multiple={multiple}
            fullWidth={fullWidth}
            disableListWrap
            ListboxComponent={ListboxComponent}
            options={options}
            open={isOpen}
            disabled={disabled}
            disableClearable
            onClose={() => setIsOpen(false)}
            className={classNames('template-designer__autocomplete', className, {
                'template-designer__autocomplete--disabled': disabled
            })}
            classes={{
                inputRoot: 'template-designer__autocomplete-image__input-root',
                input: classNames(
                    'template-designer__autocomplete-image__input-autocomplete',
                    size === 'medium' && 'template-designer__autocomplete-image__input-autocomplete--medium'
                ),
                popupIndicator: 'template-designer__autocomplete-image__popup-indicator',
                endAdornment: 'template-designer__autocomplete-image__end-adornment',
                option: classNames(
                    'template-designer__autocomplete-image__option',
                    size === 'medium' && 'template-designer__autocomplete-image__option--medium'
                ),
                noOptions: 'template-designer__autocomplete-image__no-options',
                listbox: 'template-designer__autocomplete-image__list'
            }}
            isOptionEqualToValue={(option, value) => {
                if (value.value === null) {
                    return false;
                }
                return option.value === value.value;
            }}
            renderTags={
                multiple
                    ? (value, getTagProps) =>
                          value.map((option, index) => (
                              <span key={option.value} className="template-designer__autocomplete__chip">
                                  <Chip
                                      variant="outlined"
                                      label={option.label}
                                      {...getTagProps({ index })}
                                      onDelete={
                                          option.disabled
                                              ? undefined
                                              : () => {
                                                    const newValue = [...value];
                                                    newValue.splice(index, 1);
                                                    onChange(newValue);
                                                }
                                      }
                                  />
                              </span>
                          ))
                    : undefined
            }
            renderInput={(props) => {
                return (
                    <TextInputField
                        {...props}
                        className="template-designer__autocomplete-image__input"
                        dataCyPrefix={dataCyPrefix}
                        variant="outlined"
                        size="small"
                        label={label}
                        placeholder={placeholder}
                        disabled={disabled}
                        fullWidth
                        onClick={() => setIsOpen(true)}
                        InputLabelProps={{
                            classes: {
                                shrink: 'template-designer__autocomplete-image__input__input-label--shrink'
                            }
                        }}
                        onBlur={() => setIsOpen(false)}
                        InputProps={{
                            ...(props?.InputProps || []),
                            classes: {
                                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                                /* @ts-ignore */
                                ...(props?.InputProps?.classes || []),
                                root: 'template-designer__autocomplete-image__input__input-label'
                            }
                        }}
                    />
                );
            }}
            value={value}
            renderOption={(_, option) => {
                if (option.value === '' && option.label === '') return false;
                return [option] as React.ReactNode;
            }}
        />
    );
}
