/**
 * Check if string can be casted to number.
 *? This is different from the Lodash isNumber function as it returns true if the value is already a number.
 * @param string - Input string.
 * @returns - True if string can be casted to number.
 */
const isNumeric = (string: string): boolean => {
    return !isNaN(parseFloat(string)) && isFinite(parseFloat(string));
};

/**
 * Calculate input value from expression.
 * @param value - Input value from number input field.
 * @returns - Calculated value.
 */
const calculateInputValue = (value: string): number => {
    const tokens = value.match(/[+\-*/()]|\d+(\.\d+)?|\.\d+/g) || [];

    const operators: { [key: string]: number } = {
        '+': 1,
        '-': 1,
        '*': 2,
        '/': 2
    };

    const outputQueue: (number | string)[] = [];
    const operatorStack: string[] = [];

    /**
     * Check if token is a number or operator and push to output queue or operator stack.
     */
    for (const token of tokens) {
        if (isNumeric(token)) {
            outputQueue.push(parseFloat(token));
        } else if (token in operators) {
            while (operatorStack.length > 0 && operators[token] <= operators[operatorStack[operatorStack.length - 1]]) {
                const value = operatorStack.pop();
                if (value) outputQueue.push(value);
            }
            operatorStack.push(token);
        } else if (token === '(') {
            operatorStack.push(token);
        } else if (token === ')') {
            while (operatorStack.length > 0 && operatorStack[operatorStack.length - 1] !== '(') {
                const value = operatorStack.pop();
                if (value) outputQueue.push(value);
            }
            if (operatorStack[operatorStack.length - 1] === '(') {
                operatorStack.pop();
            }
        }
    }

    /**
     * Push remaining operators to output queue.
     */
    while (operatorStack.length > 0) {
        const value = operatorStack.pop();
        if (value) outputQueue.push(value);
    }

    const evaluationStack: number[] = [];

    /**
     * Evaluate the expression using the output queue.
     */
    for (const token of outputQueue) {
        if (isNumeric(token.toString())) {
            evaluationStack.push(parseFloat(token.toString()));
        } else if (typeof token === 'string') {
            const operand2 = evaluationStack.pop();
            const operand1 = evaluationStack.pop();

            if (operand1 === undefined || operand2 === undefined) {
                throw new Error('Invalid expression');
            }

            switch (token) {
                case '+':
                    evaluationStack.push(operand1 + operand2);
                    break;
                case '-':
                    evaluationStack.push(operand1 - operand2);
                    break;
                case '*':
                    evaluationStack.push(operand1 * operand2);
                    break;
                case '/':
                    evaluationStack.push(operand1 / operand2);
                    break;
                default:
                    throw new Error('Invalid operator: ' + token);
            }
        }
    }

    if (evaluationStack.length !== 1) {
        throw new Error('Invalid expression');
    }

    return evaluationStack[0];
};

export { calculateInputValue };
