import React from 'react';
import classNames from 'classnames';
import Icon from 'components/ui-components-v2/Icon';
import './styles/main.scss';

interface Props {
    className?: string;
    isCollapsed: boolean;
    onClick?: () => void;
}

/**
 * Collapse icon for the Nestable component.
 */
const CollapseIcon = ({ className, isCollapsed, onClick }: Props) => {
    const classes = classNames('template-designer__collapse-icon', className);

    if (isCollapsed) {
        return (
            <Icon className={classes} onClick={onClick}>
                arrow_right
            </Icon>
        );
    }

    return (
        <Icon className={classes} onClick={onClick}>
            arrow_drop_down
        </Icon>
    );
};

export default CollapseIcon;
