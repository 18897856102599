import React from 'react';
import { TooltipProps } from '@mui/material';
import classNames from 'classnames';
import Tooltip from 'components/ui-components-v2/Tooltip';
import { TEMPLATE_DESIGNER_TOOLTIP_DELAY } from 'components/template-designer/constants';
import './styles/main.scss';

interface Props {
    className?: string;
    style?: React.CSSProperties;
    id?: string | null;
    isActive?: boolean;
    collapseIcon?: React.ReactElement;
    hideCollapse?: boolean;
    actions?: React.ReactNode;
    icon?: React.ReactNode;
    title?: string | React.ReactNode;
    tooltip?: string;
    tooltipDelay?: TooltipProps['enterDelay'];
    tooltipInteractive?: TooltipProps['disableInteractive'];
    children?: React.ReactNode;
    dataCyPrefix?: string;
    onClickCollapse?: () => void;
    onRightClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, key: string | null) => void;
    onDoubleClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onMouseOver?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onMouseLeave?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

/**
 * List item component used mainly for layers
 */
const ListItem = React.forwardRef<HTMLDivElement, Props>(
    (
        {
            className,
            style,
            collapseIcon,
            hideCollapse,
            id = null,
            isActive,
            actions,
            icon,
            title,
            tooltip,
            tooltipDelay = TEMPLATE_DESIGNER_TOOLTIP_DELAY,
            tooltipInteractive = false,
            children,
            dataCyPrefix,
            onClickCollapse,
            onRightClick,
            onClick,
            onDoubleClick,
            onMouseOver,
            onMouseLeave
        },
        ref
    ) => {
        return (
            <>
                <Tooltip title={tooltip || ''} enterDelay={tooltipDelay} enterNextDelay={tooltipDelay} disableInteractive={!tooltipInteractive}>
                    <div
                        ref={ref}
                        style={style}
                        className={classNames(
                            'template-designer__list-item',
                            {
                                ['template-designer__list-item--active']: isActive,
                                ['template-designer__list-item--has-actions']: !!actions
                            },
                            className
                        )}>
                        <div
                            className="template-designer__list-item__item"
                            data-cy={`${dataCyPrefix}-row-div`}
                            onClick={(event) => {
                                event.stopPropagation();
                                onClick?.(event, id);
                            }}
                            onDoubleClick={(event) => {
                                event.stopPropagation();
                                onDoubleClick?.(event);
                            }}
                            onContextMenu={(e) => onRightClick?.(e)}
                            onMouseOver={(e) => onMouseOver?.(e)}
                            onMouseLeave={(e) => onMouseLeave?.(e)}>
                            <div className="template-designer__list-item__content" id={id ?? undefined}>
                                {!hideCollapse && (
                                    <div
                                        className="template-designer__list-item__collapse-icon"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            onClickCollapse?.();
                                        }}>
                                        {collapseIcon && collapseIcon}
                                    </div>
                                )}

                                {icon && icon}

                                {typeof title !== 'string' ? (
                                    title
                                ) : (
                                    <div
                                        className={classNames('template-designer__list-item__title', {
                                            'template-designer__list-item__title--actions': actions
                                        })}>
                                        {title}
                                    </div>
                                )}
                            </div>
                        </div>
                        {actions && <div className="template-designer__list-item__actions">{actions}</div>}
                        {children}
                    </div>
                </Tooltip>
            </>
        );
    }
);

ListItem.displayName = 'ListItem';

export default ListItem;
