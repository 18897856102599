import React from 'react';
import classNames from 'classnames';
import Button from 'components/ui-components-v2/Button';
import { IconAdd, IconBolt } from 'components/template-designer/assets/icons';
import GenericIcon, { GenericSvgIcon } from 'components/ui-components/GenericIcon';
import './styles/main.scss';

interface Props {
    className?: string;
    headline?: string;
    subline?: string;
    small?: boolean;
    action?: () => void;
    buttonText?: string;
    icon?: GenericSvgIcon;
    showIcon?: boolean;
    showPlaceholderIcon?: boolean;
    children?: React.ReactNode;
    dataCyPrefix?: string;
}

/**
 * @deprecated use ui-components-cape/EmptyState instead
 */

const EmptyState = ({
    className,
    headline,
    subline,
    small,
    action,
    buttonText,
    icon,
    showIcon = true,
    showPlaceholderIcon = false,
    children,
    dataCyPrefix = 'td'
}: Props) => {
    const emptyStateIcon = (() => {
        if (!showIcon) return null;
        if (showPlaceholderIcon) return <IconBolt className="template-designer__empty-state__icon template-designer__empty-state__icon--placeholder" />;
        if (icon) return <GenericIcon icon={icon} className="template-designer__empty-state__icon" />;
        return <IconBolt className="template-designer__empty-state__icon template-designer__empty-state__icon--placeholder" />;
    })();

    return (
        <div
            data-cy={`${dataCyPrefix}-rootContainer-div`}
            className={classNames(
                'template-designer__empty-state',
                {
                    ['template-designer__empty-state--small']: small
                },
                className
            )}>
            {emptyStateIcon}
            {headline && (
                <span className="template-designer__empty-state__headline" data-cy={`${dataCyPrefix}Headline-span`}>
                    {headline}
                </span>
            )}
            {subline && (
                <span className="template-designer__empty-state__subline" data-cy={`${dataCyPrefix}Subtitle-span`}>
                    {subline}
                </span>
            )}
            {action && (
                <Button
                    data-cy={`${dataCyPrefix}Action-button`}
                    className="template-designer__empty-state__action"
                    onClick={action}
                    size="small"
                    startIcon={<IconAdd />}
                    variant="contained"
                    color="primary">
                    {buttonText}
                </Button>
            )}
            {children && <div className="template-designer__empty-state__children">{children}</div>}
        </div>
    );
};

export default EmptyState;
