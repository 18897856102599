import React from 'react';
import isEqual from 'lodash/isEqual';

interface Props {
    children: React.ReactNode;
    valuesToCheck: unknown;
}

/**
 * Memoize component
 * Check if the valuesToCheck prop has changed before re-rendering the children.
 */
const Memoize = React.memo(
    // eslint-disable-next-line react/prop-types
    ({ children }: Props) => children,
    (prevProps, nextProps) => isEqual(prevProps.valuesToCheck, nextProps.valuesToCheck)
);

Memoize.displayName = 'Memoize';

export { Memoize };
