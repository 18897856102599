import React, { useEffect, useRef } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import classNames from 'classnames';
import './styles/main.scss';

interface Props extends Omit<TextFieldProps, 'onChange' | 'onBlur'> {
    className?: string;
    defaultValue?: string;
    value?: string;
    label?: string | JSX.Element;
    onChange?: (value: string) => void;
    onBlur?: (value: string) => void;
    onFocus?: () => void;
    selected?: boolean;
    disabled?: boolean;
    multiline?: boolean;
    dataCyPrefix?: string;
}

const TextInputField = ({
    className,
    defaultValue,
    value,
    label,
    onChange,
    onBlur,
    onFocus,
    selected,
    disabled,
    multiline,
    error,
    dataCyPrefix,
    ...props
}: Props) => {
    const inputRef = useRef<HTMLInputElement>(null);

    /**
     * Select all text inside the input element when it is selected.
     */
    useEffect(() => {
        if (!selected) return;
        // Select all text inside the input element
        inputRef?.current?.select();
    }, [selected]);

    return (
        <TextField
            inputRef={inputRef}
            className={classNames('template-designer__text-input-field', className, {
                'template-designer__text-input-field--disabled': disabled,
                'template-designer__text-input-field--multiline': multiline
            })}
            error={error}
            size="small"
            type="text"
            disabled={disabled}
            label={label}
            defaultValue={defaultValue}
            value={value}
            onChange={(event) => onChange?.(event.target.value)}
            onBlur={(event) => onBlur?.(event.target.value)}
            onFocus={onFocus}
            multiline={multiline}
            data-cy={`${dataCyPrefix}-text-input`}
            {...props}
            InputLabelProps={{
                ...(props?.InputLabelProps || []),
                className: classNames(
                    'template-designer__text-input-field__input-label',
                    props?.InputLabelProps?.className,
                    props?.InputProps?.className || []
                ),
                classes: {
                    ...(props?.InputLabelProps?.classes || []),
                    root: classNames(
                        'template-designer__text-input-field__input-label__root',
                        props?.InputLabelProps?.classes?.root,
                        props?.InputProps?.classes?.root
                    )
                }
            }}
        />
    );
};

export { TextInputField };
