import React, { useMemo, useState } from 'react';
import { Portal } from '@mui/base';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import Format from 'components/template-designer/types/format.type';
import Translation from 'components/data/Translation';
import Template, { State, View } from 'components/template-designer/types/template.type';
import FormatHelpers from 'components/template-designer/helpers/format.helpers';
import { LayerPropertiesHelpers } from 'components/template-designer/helpers/layer-properties.helpers';
import ConfirmDialog from 'components/ui-components/ConfirmDialog';
import { CanvasHelpers } from 'components/template-designer/helpers/canvas.helpers';
import Dropdown, { DropdownItem, DropdownProps } from '../../ui-components/dropdown';
import FormatManagementCopyToDialog from '../../format-management/components/format-management-copy-dialog';
import './styles/main.scss';

interface Props extends Omit<DropdownProps, 'items'> {
    format: Format;
    hideOptions?: {
        scroll?: boolean;
        copyStylingTo?: boolean;
        applyOverwrites?: boolean;
        setVisibilityFocus?: boolean;
        setVisibilityOn?: boolean;
        setVisibilityOff?: boolean;
        changeTitle?: boolean;
        resetOverwrites?: boolean;
        delete?: boolean;
    };
    onClickChangeTitle?: (show: boolean) => void;
}

interface Data {
    displayFormats: State['displayFormats'];
    selectedFormats: State['selectedFormats'];
    frameType: View['frameType'];
    formats: Template['formats'];
}

const FormatDropdownOptions = ({ trigger, open, dropdownPosition, onOpen, onClose, format, hideOptions, onClickChangeTitle }: Props) => {
    const { formats, displayFormats, selectedFormats, frameType } = useComponentStore<Data>('TemplateDesigner', {
        fields: {
            displayFormats: 'state.displayFormats',
            formats: 'formats',
            selectedFormats: 'state.selectedFormats',
            frameType: 'view.frameType'
        }
    });

    const [formatToCopy, setFormatToCopy] = useState<Format['key'] | null>(null);
    const [deleteFormatKeys, setDeleteFormatKeys] = useState<Format['key'][] | null>(null);
    const currentDisplayFormat = displayFormats.includes(format.key);

    /**
     * Check how many formats are shown.
     */
    const checkAmountIfShown = (): number => {
        const alreadyVisible = displayFormats.filter((displayFormat) => selectedFormats.includes(displayFormat)).length;
        return displayFormats.length + selectedFormats.length - alreadyVisible;
    };

    const dropdownOptions: DropdownItem[] = useMemo(() => {
        const maxVisibibleFormats = FormatHelpers.getMaxVisibleFormats();

        if (selectedFormats.length === 1) {
            // Single format selected.
            return [
                {
                    key: 'scroll',
                    icon: 'gps_fixed',
                    disabled: format.x === undefined || format.y === undefined,
                    hide: !currentDisplayFormat || hideOptions?.scroll,
                    onClick: () => CanvasHelpers.scrollToFormat(format),
                    children: Translation.get('sidebarLeft.formats.actions.scrollToFormat', 'template-designer')
                },
                {
                    key: 'copyStylingTo',
                    icon: 'add_box',
                    disabled: formats.length === 1,
                    hide: hideOptions?.copyStylingTo,
                    onClick: () => setFormatToCopy(format.key),
                    children: Translation.get('sidebarLeft.formats.actions.copyFormatStylingTo', 'template-designer')
                },
                {
                    key: 'applyOverwrites',
                    icon: 'auto_mode',
                    hide: !FormatHelpers.formatHasOverwrites(format.key) || hideOptions?.applyOverwrites,
                    onClick: () => LayerPropertiesHelpers.moveOverwritesToGeneral(format.key),
                    children: Translation.get('sidebarLeft.formats.actions.applyOverwrites', 'template-designer')
                },
                {
                    key: 'setVisibilityFocus',
                    icon: 'visibility',
                    hide: formats.length < maxVisibibleFormats || hideOptions?.setVisibilityFocus,
                    onClick: () => FormatHelpers.setDisplayFormatsFocus(format.key, true),
                    children: Translation.get('sidebarLeft.formats.actions.showOnly_one', 'template-designer')
                },
                {
                    key: 'setVisibilityOn',
                    icon: 'visibility',
                    hide: displayFormats.includes(format.key) || hideOptions?.setVisibilityOn,
                    disabled: displayFormats.length + 1 > maxVisibibleFormats,
                    tooltip:
                        displayFormats.length + 1 > maxVisibibleFormats
                            ? Translation.get('sidebarLeft.formats.dialogs.visibilityCap', 'template-designer')
                            : undefined,
                    onClick: () => FormatHelpers.setDisplayFormats([format.key], true),
                    children: Translation.get('sidebarLeft.formats.actions.show_one', 'template-designer')
                },
                {
                    key: 'setVisibilityOff',
                    icon: 'visibility_off',
                    hide: !displayFormats.includes(format.key) || hideOptions?.setVisibilityOff,
                    onClick: () => FormatHelpers.setDisplayFormats([format.key], false),
                    children: Translation.get('sidebarLeft.formats.actions.hide_one', 'template-designer')
                },
                {
                    key: 'changeTitle',
                    icon: 'edit_note',
                    hide: hideOptions?.changeTitle,
                    onClick: () => {
                        FormatHelpers.setSelectedFormats(format.key, { ctrlKey: false, shiftKey: false });
                        setTimeout(() => onClickChangeTitle?.(true), 50);
                    },
                    children: Translation.get('sidebarLeft.formats.actions.changeTitle', 'template-designer')
                },
                {
                    key: 'resetOverwrites',
                    hide: !FormatHelpers.formatHasOverwrites(format.key) || hideOptions?.resetOverwrites,
                    icon: 'restart_alt',
                    onClick: () => LayerPropertiesHelpers.resetFormatOverwrites(format.key),
                    children: Translation.get('sidebarLeft.formats.actions.resetOverwrite_one', 'template-designer')
                },
                {
                    key: 'delete',
                    icon: 'delete_outline',
                    hide: hideOptions?.delete,
                    className: 'template-designer__format-dropdown-options__option--delete',
                    onClick: () => setDeleteFormatKeys([format.key]),
                    children: Translation.get('sidebarLeft.formats.actions.deleteFormat_one', 'template-designer')
                }
            ];
        }

        // Multiple formats selected.
        return [
            {
                key: 'setVisibilityOn',
                icon: 'visibility',
                hide: !selectedFormats.some((selectedFormat) => !displayFormats.includes(selectedFormat)) || hideOptions?.setVisibilityOn,
                disabled: checkAmountIfShown() > maxVisibibleFormats,
                tooltip:
                    checkAmountIfShown() > maxVisibibleFormats ? Translation.get('sidebarLeft.formats.dialogs.visibilityCap', 'template-designer') : undefined,
                onClick: () => FormatHelpers.setDisplayFormatsFocus(selectedFormats, true),
                children: Translation.get('sidebarLeft.formats.actions.show_other', 'template-designer')
            },
            {
                key: 'setVisibilityFocus',
                icon: 'visibility',
                hide: formats.length < maxVisibibleFormats || hideOptions?.setVisibilityFocus,
                disabled: selectedFormats.length > maxVisibibleFormats,
                tooltip:
                    selectedFormats.length > maxVisibibleFormats
                        ? Translation.get('sidebarLeft.formats.dialogs.visibilityCap', 'template-designer')
                        : undefined,
                onClick: () => FormatHelpers.setDisplayFormatsFocus(selectedFormats, true),
                children: Translation.get('sidebarLeft.formats.actions.showOnly_other', 'template-designer')
            },
            {
                key: 'setVisibilityOff',
                icon: 'visibility_off',
                hide: !selectedFormats.some((selectedFormat) => displayFormats.includes(selectedFormat)) || hideOptions?.setVisibilityOff,
                onClick: () => FormatHelpers.setDisplayFormats(selectedFormats, false),
                children: Translation.get('sidebarLeft.formats.actions.hide_other', 'template-designer')
            },
            {
                key: 'resetOverwrites',
                hide: !selectedFormats.some((selectedFormat) => FormatHelpers.formatHasOverwrites(selectedFormat)) || hideOptions?.resetOverwrites,
                icon: 'restart_alt',
                onClick: () => LayerPropertiesHelpers.resetFormatOverwrites(selectedFormats),
                children: Translation.get('sidebarLeft.formats.actions.resetOverwrite_other', 'template-designer')
            },
            {
                key: 'delete',
                icon: 'delete_outline',
                hide: hideOptions?.delete,
                className: 'template-designer__format-dropdown-options__option--delete',
                onClick: () => setDeleteFormatKeys(selectedFormats),
                children: Translation.get('sidebarLeft.formats.actions.deleteFormat_other', 'template-designer', {
                    totalFormats: selectedFormats.length
                })
            }
        ];
    }, [formats.length, hideOptions, displayFormats, selectedFormats, format, currentDisplayFormat]);

    return (
        <>
            <Dropdown trigger={trigger} open={open} dropdownPosition={dropdownPosition} onOpen={onOpen} onClose={onClose} items={dropdownOptions} />

            {!!deleteFormatKeys && (
                <ConfirmDialog
                    open
                    title={
                        deleteFormatKeys.length === 1
                            ? Translation.get('sidebarLeft.formats.dialogs.delete.title_one', 'template-designer')
                            : Translation.get('sidebarLeft.formats.dialogs.delete.title_other', 'template-designer')
                    }
                    description={
                        deleteFormatKeys.length === 1
                            ? Translation.get('sidebarLeft.formats.dialogs.delete.description_one', 'template-designer')
                            : Translation.get('sidebarLeft.formats.dialogs.delete.description_other', 'template-designer')
                    }
                    onConfirm={() => {
                        if (deleteFormatKeys === null) return;
                        FormatHelpers.deleteFormat(deleteFormatKeys);
                        setDeleteFormatKeys(null);
                    }}
                    onClose={() => setDeleteFormatKeys(null)}
                    confirmColor="error"
                />
            )}

            {!!formatToCopy && (
                <Portal>
                    <FormatManagementCopyToDialog
                        onClose={() => setFormatToCopy(null)}
                        formats={formats.filter((format) => format.key !== formatToCopy)}
                        formatToCopy={formatToCopy}
                        frameType={frameType}
                    />
                </Portal>
            )}
        </>
    );
};

export { FormatDropdownOptions };
