import React from 'react';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Translation from 'components/data/Translation';
import '../styles/overwrite-indicator.scss';

interface Props {
    totalOverwrites: number;
}

const OverwriteIndicator = ({ totalOverwrites }: Props) => {
    return (
        <Tooltip
            title={Translation.get('sidebarRight.labels.containsOverwrites', 'template-designer', {
                total: totalOverwrites,
                count: totalOverwrites
            })}>
            <div className="template-designer__expand-section__overwrite-indicator" />
        </Tooltip>
    );
};

export { OverwriteIndicator };
