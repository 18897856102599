import React from 'react';
import Icon from '@mui/material/Icon/Icon';
import IconButton from '@mui/material/IconButton/IconButton';
import classNames from 'classnames';

interface Props {
    className?: string;
    onClose: () => void;
    children: JSX.Element | JSX.Element[] | string;
}

const DialogTitle = ({ className, onClose, children }: Props) => (
    <div className={classNames('template-designer__dialog__title', className)}>
        <div>{children}</div>
        {onClose && (
            <IconButton className="template-designer__dialog__close-button" onClick={onClose}>
                <Icon className="template-designer__dialog__close-icon" fontSize="inherit">
                    close
                </Icon>
            </IconButton>
        )}
    </div>
);

export default DialogTitle;
